var ChargeOver = ChargeOver || {};

ChargeOver.Customer = {

	ACTION_CREATE: 'Customer.create',
	
	create: function(creditcard, callback)
	{
		ChargeOver.Core.jsonp(ChargeOver.Customer.ACTION_CREATE, creditcard, callback);
		return true;
	}

};