var ChargeOver = ChargeOver || {};

ChargeOver.Signup = {

	ACTION_SIGNUP: 'Signup.signup',
	ACTION_ONETIME: 'Signup.onetime',

	signup: function(data, callback)
	{
		ChargeOver.Core.jsonp(ChargeOver.Signup.ACTION_SIGNUP, data, callback);
		return true;
	},

	onetime: function(data, callback)
	{
		ChargeOver.Core.jsonp(ChargeOver.Signup.ACTION_ONETIME, data, callback);
		return true;
	}

};