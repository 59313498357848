var ChargeOver = ChargeOver || {};

ChargeOver.ACH = {

	ACTION_TOKENIZE: 'ACH.tokenize',
	ACTION_GET: 'ACH.get', 
	ACTION_VALIDATE: 'ACH.validate',
	
	tokenize: function(ach, callback)
	{
		ChargeOver.Core.jsonp(ChargeOver.ACH.ACTION_TOKENIZE, ach, callback);
		return true;
	},

	get: function(token, callback)
	{
		ChargeOver.Core.jsonp(ChargeOver.ACH.ACTION_GET, token, callback);
	},

	validate: function(ach, callback)
	{
		ChargeOver.Core.jsonp(ChargeOver.ACH.ACTION_VALIDATE, ach, callback);
		return true;
	}
};